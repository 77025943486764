html {
    --color-base: #231F20;
    --color-primary: #C52337;
    --color-secondary: #ecb030;
    --color-sub-1: #FFFBD4;
    --color-sub-2: #00be90;
    --color-sub-3: #64ccc9;
    --color-sub-4: #cc9064;
    --color-sub-5: #cc6464;
    --transition: all ease 0.5s;
    --border-radius-sm: 5px;
    --border-radius-md: 10px;
    --border-radius-lg: 20px;
    --gutter : 20px;
    @media (max-width : 400px){
    --gutter : 16px;

    }
    --font-secondary: 'Montserrat', sans-serif;
  }
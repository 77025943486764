.pc {
	.page-head,
	.bottom-menu {
		display: none;
	}
	.container {
		max-width: 2048px;
		background: url("../../assets/images/pc.jpg") center no-repeat;
		background-size: cover;
		position: relative;
		.logo {
			position: absolute;
			top: 20px;
			left: 20px;
			width: 8%;
		}

		.page-content {
			padding: 60px 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			.body-content {
				max-width: 100%;
				width: 100%;
			}
			.page {
				display: flex;
				align-items: center;
				position: relative;
				padding: 0 5%;

				.bl {
					width: 50%;
				}
				.bl-left {
					.title {
						margin: 0 auto -5%;
						.img {
							width: 70%;
							margin: auto;
							img {
								width: 100%;
							}
						}
					}
					.product {
						.img {
							width: 60%;
							margin: auto;
						}

						position: relative;

						.prize {
							position: absolute;
							z-index: 2;

							&.p1 {
								width: 30%;
								top: 5%;
								left: 5%;
							}

							&.p2 {
								width: 35%;
								top: 25%;
								right: 0%;
							}

							&.p3 {
								width: 45%;
								top: 66%;
								left: 0%;
							}
						}
					}
				}
				.bl-right {
					.head {
						.title {
							text-shadow: 1.7701672315597534px 4.425417900085449px 0px #000;
							color: white;
							font-size: 4em;
							text-align: center;
							font-weight: 700;
							text-align: center;
							justify-content: center;
							@media (max-width: 1600px) {
								font-size: 3em;
							}
						}
						.text {
							text-align: center;
							color: white;
							font-size: 1.375em;
							span {
								color: var(--color-secondary);
							}
							@media (max-width: 1600px) {
								font-size: 1em;
							}
						}
						.time {
							text-align: center;
							color: #fffbd4;
							font-weight: 600;
							font-size: 1.5em;
							@media (max-width: 1600px) {
								font-size: 1.25em;
							}
							strong {
								font-weight: 900;
							}
						}
					}
					.body {
						.code {
							text-align: center;
							background: url("../../assets/images/57.png ") center no-repeat;
							background-size: 100% 100%;
							margin-left: auto;
							margin-right: auto;
							width: 50%;
							.wrap {
								display: inline-block;
								position: relative;
							}
							ul {
								display: inline-flex;
								justify-content: center;
								align-items: center;
								margin: 0;
								padding: 10px 64px;

								font-size: 1.5em;
								color: var(--color-secondary);
								font-weight: 900;
								letter-spacing: 10px;
								position: relative;
								font-family: var(--font-secondary);

								z-index: 2;
								@media (max-width: 1600px) {
									font-size: 2.5em;
								}
							}
						}
						.gift {
							.img {
								width: 50%;
								margin: 0 auto;
							}
						}
						.date {
							text-align: center;
							color: white;
							font-size: 1.375em;
							span {
								color: var(--color-secondary);
							}
							@media (max-width: 1600px) {
								font-size: 1em;
							}
						}
						.winner {
							.text {
								text-align: center;
								font-size: 1.5em;
								color: white;
								text-transform: uppercase;
								font-weight: 600;
								@media (max-width: 1600px) {
									font-size: 1.375em;
								}
							}
							.information {
								font-size: 1.25em;
								color: white;
								font-weight: normal;
								width: 50%;
								border: 2px solid var(--color-secondary);
								border-radius: 10px;
								padding: var(--gutter);
								margin: auto;
								span {
									color: var(--color-secondary);
									font-size: 1.65em;
								}
							}
						}
						.button {
							a {
								width: 400px;
								font-size: 1.5em;
								&::before {
									width: 90px;
									height: 110px;
								}
							}
						}
					}
				}
			}
		}
	}

	.lds-spinner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	@keyframes lds-roller {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	.lds-roller {
		position: relative;
		display: inline-block;
		height: 64px;
		width: 64px;

		div {
			animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			transform-origin: 32px 32px;

			&:after {
				position: absolute;
				display: block;
				background: var(--color-secondary);
				border-radius: 50%;
				content: " ";
				margin: -3px 0 0 -3px;
				height: 6px;
				width: 6px;
			}

			&:nth-child(1) {
				animation-delay: -0.036s;

				&:after {
					top: 50px;
					left: 50px;
				}
			}

			&:nth-child(2) {
				animation-delay: -0.072s;

				&:after {
					top: 54px;
					left: 45px;
				}
			}

			&:nth-child(3) {
				animation-delay: -0.108s;

				&:after {
					top: 57px;
					left: 39px;
				}
			}

			&:nth-child(4) {
				animation-delay: -0.144s;

				&:after {
					top: 58px;
					left: 32px;
				}
			}

			&:nth-child(5) {
				animation-delay: -0.18s;

				&:after {
					top: 57px;
					left: 25px;
				}
			}

			&:nth-child(6) {
				animation-delay: -0.216s;

				&:after {
					top: 54px;
					left: 19px;
				}
			}

			&:nth-child(7) {
				animation-delay: -0.252s;

				&:after {
					top: 50px;
					left: 14px;
				}
			}

			&:nth-child(8) {
				animation-delay: -0.288s;

				&:after {
					top: 45px;
					left: 10px;
				}
			}
		}
	}
}

@keyframes arrow {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0.5;
	}
}

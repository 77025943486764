.home {
	&.getCard {
			.page-head {
					margin: 0 auto 20px;
					opacity: 0;
			}
	}

	.form {
			padding: 0 var(--gutter);
	}

	.product {
			.img {
					width: 80%;
					margin: auto;
			}

			position: relative;

			.prize {
					position: absolute;
					z-index: 2;

					&.p1 {
							width: 25%;
							top: 5%;
							left: 5%;
					}

					&.p2 {
							width: 30%;
							top: 25%;
							right: 0%;
					}

					&.p3 {
							width: 40%;
							top: 66%;
							left: 0%;
					}
			}
	}

	.label {
			position: relative;
			z-index: 2;
	}

	.code {
			width: calc(100% + 40px);
			margin-left: -20px;
			position: relative;
			z-index: 1;

			@media (max-width: 400px) {
					width: calc(100% + 32px);
					margin-left: -16px;
			}

			label {
					display: inline-block;
					padding: 6px calc(var(--gutter) * 1.5) 6px var(--gutter);
					background: url('../../assets/images/30.png') center no-repeat;
					background-size: 100% 100%;
					color: black;
					font-weight: 900;
					font-family: var(--font-secondary);
			}

			.value {
					padding: 0px var(--gutter);
					background: url('../../assets/images/31.png') center no-repeat;
					background-size: 100% 100%;
		
					img {
							width: 60%;
							margin: auto;
					}
					span {
							font-size: 2em;
							font-weight: bold;
							text-align: center;
							padding-bottom: 12px;
							padding-top: 8px;
							display: block;
					}

			}
	}

	.star-noti {
			position: relative;
			z-index: 1;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
					font-size: 1em;
					color: #FFFBD4;
					padding-right: 12px;
					font-style: italic;
					font-weight: bold;
			}

			.noti {
					font-size: 1em;
					color: black;
					padding: 4px 12px;
					background-size: cover;
					text-transform: uppercase;
					font-weight: bold;
					border: 2px solid var(--color-secondary);
					border-radius: 5px;
					font-style: italic;

					strong {
							color: var(--color-primary);
							padding-right: 4px;
					}
			}
	}

	.collection {
			padding: var(--gutter);
			position: relative;
			z-index: 1;

			&.one-star {
					.main {

							.img {
									.active {
											clip-path: polygon(0 0, 20% 0, 20% 100%, 0% 100%);
									}
							}
					}
			}

			&.two-star {
					.main {

							.img {
									.active {
											clip-path: polygon(0 0, 35% 0, 35% 100%, 0% 100%);
									}
							}
					}
			}

			&.three-star {
					.main {

							.img {
									.active {
											clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
									}
							}
					}
			}

			&.four-star {
					.main {
							.img {
									.active {
											clip-path: polygon(0 0, 66% 0, 66% 100%, 0% 100%);
									}
							}
					}
			}

			&.five-star {
					.main {

							.img {
									.active {
											clip-path: polygon(0 0, 80% 0, 80% 100%, 0% 100%);
									}
							}
					}
			}

			&.six-star {
					.main {

							.img {
									.active {
											clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
									}
							}
					}
			}

			.main {

					.img {
							position: relative;

							.active {
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									z-index: 2;
									clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
									transition: var(--transition);
							}
					}
			}

			.star-status {
					font-size: .875em;
					color: white;

					span {
							font-weight: bold;
							font-size: 1.5em;
					}

					img {
							width: 24px;
							margin-bottom: -2px;
					}
			}
	}

	.bl-prize {
			position: relative;
			width: 100%;
			aspect-ratio: 3/ 2;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			z-index: 1;

			&::before {
					width: 400px;
					height: 500px;
					display: block;
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					background: url('../../assets/images/37.png') center no-repeat;
					background-size: 100%;
					opacity: 2;
			}

			&::after {
					width: 400px;
					height: 400px;
					display: block;
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					background: url('../../assets/images/34.png') center no-repeat;
					background-size: 100%;
					z-index: 1;
			}

			.wrap {
					position: relative;

					&::after {
							width: 400px;
							height: 400px;
							display: block;
							content: "";
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							background: url('../../assets/images/36.png') center no-repeat;
							background-size: 100%;
					}
			}

			.img {
					width: 80%;
					margin: auto;
					position: relative;
					z-index: 2;
			}
	}
	.prize-status {
			.list {

					.item {
							aspect-ratio: 705/224;
							width: 100%;
							background: url('../../assets/images/49.png') center no-repeat;
							background-size: 100% 100%;
							padding: 4% 10% 5px 15%;
							&:last-child {
									.label {
											img {
													width: 100%;
											}
									}
									
							}
							label {
									font-size: .875em;
									color: white;
									font-weight: bold;
									margin-bottom: 4px;
									display: block;
									
							}
							.progress {
									display: flex;
									.bar {
											width: calc(100% - 80px);
											display: flex;
											align-items: center;
											padding: 0 6px ;
											border: 1px solid var(--color-secondary);
											position: relative;
											&::before {
													width: calc(100% - 8px);
													height: 10px;
													display: block;
													content: "";
													background-color: rgba(#ecb030 , .2);
													position: absolute;
													top: 50%;
													left: 50%;
													transform: translate(-50%,-50%);
											}
										 
									}
									.value {
											width: 80px;
											padding-left: 12px;
											margin-bottom: 4px;
									}
							}
							.label {
									img {
											width: 80%;
									}
							}
					}
			}
	}
	.prize-info {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			position: relative;
			z-index: 2;

			.code {
					font-size: 1.25em;
					text-align: center;
					font-weight: bold;
					color: black;
					background-size: cover;
					text-transform: uppercase;
					font-weight: bold;
					border: 2px solid var(--color-secondary);
					display: inline-block;
					padding: 2px 16px;
					border-radius: 5px;
					letter-spacing: 2px;
					margin: 10px 0;
					font-style: italic;

			}

			p {
					text-align: center;
					margin: 0;
					font-size: .875em;
					color: #FFFBD4;
					width: 100%;
					font-weight: 600;
			}

	}

	.note {
			p {
					text-align: center;
					font-size: .875em;
					color: white;
					width: 100%;
					margin: 0 0 6px 0;

					&:last-child {
							margin: 0;
					}
			}
	}
	
	.bottom-logo {
			width: 50%;
			max-width: 300px;

	}

	.wrapper {
			min-height: calc(100vh - 250px);
	}

	.block2 {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
	}
}

.separate {
	img {
			width: 70%;
			margin: 16px auto;
	}
}

.title {
	font-size: .875em;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	position: relative;
	z-index: 3;

	&.sm {
			img {
					width: 30px;
			}
	}

	img {
			width: 30%;
			transform: translateY(15px);

	}
}

.tnc-pc {
  .top {
    position: relative;
  }
  .container {
    .logo {
      position: absolute;
      width: 15%;
      top: 5%;
      left: 5%;
    }
  }

  .tagline {
    position: absolute;
    width: 30%;
    top: 0;
    left: 50%;
    transform: translate(-50%, -10%);
  }
  .tnc-title {
    position: absolute;
    width: 30%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .top-content {
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
  }
  .bl1 {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 7%;
  }
  .bl2 {
    width: 65%;
    margin: auto;
    p {
      font-size: 1.25em;
      color: white;
      margin: 0 0 12px 0;
      a {
        color: var(--color-secondary);
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
  .prize-status {
    width: 65%;
    .list {
      display: flex;
      gap: 40px 20px;
      flex-wrap: wrap;
      justify-content: center;
      .item {
        aspect-ratio: 705/260;
        width: calc((100% - 20px) / 2);
        background: url("../../assets/images/49_2.png") center no-repeat;
        background-size: 100% 100%;
        padding: 3% 4% 5px 8%;
        &.special {
          background: url("../../assets/images/102.png") center no-repeat;
          background-size: 100% 100%;
        }
        &:last-child {
          .label {
            img {
              width: 100%;
            }
          }
        }
        label {
          font-size: 1.375em;
          color: white;
          font-weight: bold;
          margin-bottom: 10px;
          display: block;
        }
        .progress {
          display: flex;
          .bar {
            width: calc(100% - 150px);
            display: flex;
            align-items: center;
            padding: 0 6px;
            border: 1px solid var(--color-secondary);
            position: relative;
            &::before {
              width: calc(100% - 8px);
              height: 10px;
              display: block;
              content: "";
              background-color: rgba(#ecb030, 0.2);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .value {
            width: 150px;
            padding-left: 12px;
            margin-bottom: 4px;
          }
        }
        .label {
          img {
            width: 80%;
          }
        }
      }
    }
  }
  .container {
    .page-content {
      padding: 0;

      .page {
        padding: 0;
        display: block;
        .content-wrap {
          height: auto;
          overflow: inherit;
        }
        .content {
          background: #684e16 url("../../assets/images/101.png") center top
            no-repeat;
          background-size: 100%;
          padding: 60px 0;
        }
        .bl {
          width: 60%;
          margin: auto;
          h4 {
            font-size: 1.375em;
          }
          .name {
            .img {
              img {
                max-width: 500px;
              }
            }
          }
          p {
            font-size: 1.25em;
            margin: 0 0 12px 0;
          }
          .table table td {
            font-size: 1.25em;
          }
          .img img {
            max-width: 290px;
          }
          .table {
            table {
              width: 100%;
            }
            thead {
              td {
                font-weight: bold !important;
              }
            }
            tr:first-child {
              td {
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
}


.tnc {
	.content {
			background: rgba(#ecb030, .4);
			padding: var(--gutter);
			&-wrap {
					height: 50vh;
					overflow-y: auto;
			}
			.bl {
					margin-bottom: 12px;

					&:last-child {
							margin-bottom: 0;
					}

					.name {
							img {
									width: 80%;
									margin: auto;
							}
					}
			}

			h4 {
					font-size: 1em;
					font-weight: bold;
					color: white;
					margin: 0 0 10px 0;
			}

			p {
					margin: 0 0 8px 0;
					font-size: .875em;
					color: white;
					padding-left: var(--gutter);

					&:last-child {
							margin: 0;
					}

			}

			.table {
					width: 100%;
					overflow-x: auto;
					margin-bottom: 16px;
					&.full {
							table {
									width: 100%;
							}
					}
					&.size-lg {
							table {
									width: 1000px;
							}
					}

					table {
							width: 800px;
							border-collapse: collapse;

							tr:first-child {


									td {
											font-weight: bold;
									}
							}

							td {
									border: 1px solid white;
									color: white;
									padding: 8px 12px;
									font-size: .875em;
									text-align: center;
									vertical-align: middle;


									p {
											font-size: 0.875em;
											text-align: center;
											margin: 0 0 8px 0;
											word-break: break-word;

											&:last-child {
													margin: 0;
											}
									}
							}
					 
					}
				 
			}
			.cap {
					text-transform: uppercase;
			}
			.img {
					margin-bottom: 16px;
				
					img {
							width: 60%;
							margin: auto;
					}
			}
			strong {
					font-weight: bold;
			}
	}
}
#countdown {
	width: 300px;
	margin: 20px auto;
	color: #000;
}
#countdown ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
			-ms-flex-pack: justify;
					justify-content: space-between;
	padding: 0;
	margin: 0;
}
#countdown ul li {
	list-style-type: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
			flex-wrap: wrap;
	width: calc(25% - 10px);
	-webkit-box-pack: center;
			-ms-flex-pack: center;
					justify-content: center;
	padding: 15px 10px;
	background-color: #B37F2A;
	border-radius: 10px;
	font-size: 12px;
}
#countdown ul li span {
	display: block;
	width: 100%;
	text-align: center;
	font-weight: bold;
	font-size: 30px;
	line-height: 1;
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.reject {
	.container {
			.page-content {
					padding: 20px var(--gutter) 0;
					.wrap {
					}
			}
	}
	.product {
			.img {
					width: 100%;
					margin: auto;
			}
			position: relative;
			.prize {
					position: absolute;
					z-index: 2;
					&.p1 {
							width: 30%;
							top: 15%;
							left: 5%;
					}
					&.p2 {
							width: 35%;
							top: 25%;
							right: 0%;
					}
					&.p3 {
							width: 50%;
							position: relative;
							transform: translate(15%, -50%);
					}
			}
	}
	.noti {
			margin-top: -30%;
			width: calc(100% +  40px);
			margin-left: -20px;
			@media (max-width: 400px) {
					width: calc(100% +  32px);
					margin-left: -16px;
			}
		
	}
}

.product  {
	.main {
		position: relative;
		&::before {
			width: 150%;
			display: block;
			content: "";
			height: 200%;
			position: absolute;
			top: 30%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: url('../../assets/images/flare.png') center no-repeat;
			background-size: 100%;
			
		}
		img {
			position: relative;
			z-index: 2;
		}
	}
}
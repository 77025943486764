.button {
	display: block;
	&.center {
		text-align: center;
	}
	&.right {
		text-align: right;
	}
	&.red {
		div {
			background-color: #ed2e24;
			border: 1px solid white;

			color: white;
		}
	}
	&.third {
		div {
			width: 280px;
			font-size: 0.875em;
		}
	}
	&.disabled {
		div {
			pointer-events: none;
			background-color: grey;
		}
	}
	&.secondary {
		div {
			background-color: #a39d9d;
			border: 1px solid white;
			color: white;
		}
	}

	div {
		text-decoration: none;
		color: var(--color-base);
		background: url("../../assets/images/7.png") center no-repeat;
		background-size: 100% 100%;
		align-items: center;
		justify-content: center;
		width: 320px;
		font-size: 0.8125em;
		// padding-left: 20px;
		padding-left: 10px;
		padding-top: 17px;
		text-transform: uppercase;
		font-weight: bold;
		color: white;
		transition: all ease 0.5s;
		display: inline-flex;
		position: relative;
		aspect-ratio: 3/ 1;
		@media (max-width: 400px) {
			width: 280px;
			font-size: .75em;
		}
	}
}

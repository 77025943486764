@import "fonts";
@import "setup";
@import "variables";
@import "reset";

body {
  font-family: "SFU Futura";
  font-size: 16px;
  line-height: 1.5;
  font-weight: normal;
  color: var(--color-secondary);
}

.form {
  form {
    .form-groups {
      margin-bottom: var(--gutter);
      &.checkbox {
        padding-left: 36px;
        margin-bottom: 16px;
        label {
          font-size: 0.875em;
          display: block;
          line-height: 1.2;
          color: var(--color-secondary);
          position: relative;
          a {
            color: white;
            text-decoration: none;
            margin-right: 2px;
          }
        }
      }
      .label {
        font-size: 0.75em;
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        img {
          width: 24px;
        }
      }
      &.captcha {
        padding-left: 30px;

        .form-groups-w {
          display: flex;
          align-items: center;
          border: 1px solid var(--color-secondary);
          border-radius: 5px;
          input {
            border: none;
            text-align: left;
            font-size: 1.125em;
            font-style: italic;
            color: var(--color-secondary);
            transition: all ease 0.5s;
            padding: 0 20px;
            font-weight: 900;
            text-align: center;
            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: rgba(#fff, 0.8);
              font-weight: normal;
            }
            &::-moz-placeholder {
              /* Firefox 19+ */
              color: rgba(#fff, 0.8);
              font-weight: normal;
            }
            &:-ms-input-placeholder {
              /* IE 10+ */
              color: rgba(#fff, 0.8);
              font-weight: normal;
            }
            &:-moz-placeholder {
              /* Firefox 18- */
              color: rgba(#fff, 0.8);
              font-weight: normal;
            }
            &:focus {
              box-shadow: 0 0 20px 2px rgba(#ecb030, 0.8);
            }
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      input[type="checkbox"],
      input[type="radio"] {
        display: none;
      }
      .form-groups-w {
        position: relative;
        .title {
          font-size: 12px;
          position: absolute;
          top: 0;
          transform: translateY(-100%);
          width: 100%;
          padding-left: 6px;
          opacity: 0.5;
        }
      }
      .error {
        font-size: 0.875em;
        color: #f24822;
        padding-top: 8px;
      }

      input,
      select {
        height: 50px;
        width: 100%;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid var(--color-base);
        padding: 0 6px;
        font-style: italic;
        outline: none;
        color: var(--color-base);
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: rgba(#fff, 0.8);
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: rgba(#fff, 0.8);
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: rgba(#fff, 0.8);
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: rgba(#fff, 0.8);
        }
      }
      select {
        padding: 0 2px;
      }
      input[type="checkbox"] + label:hover,
      input[type="radio"] + label:hover {
        cursor: pointer;
      }
      input[type="checkbox"] + label span,
      input[type="radio"] + label span {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        margin: -2px 8px 0 -30px;
        vertical-align: middle;
        background: transparent;
        cursor: pointer;
        border-radius: 4px;
        border: 2px solid rgba(#ecb030, 0.5);
        transition: all ease 0.5s;
      }
      input[type="checkbox"] + label span:before,
      input[type="radio"] + label span:before {
        content: "";
        width: 14px;
        height: 14px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url("../images/8.png") center no-repeat;
        background-size: 80%;
        opacity: 0;
        transition: all ease 0.5s;
      }
      input[type="checkbox"]:checked + label span,
      input[type="radio"]:checked + label span {
        border: 2px solid rgba(#ecb030, 1);
      }
      input[type="checkbox"]:checked + label span:before,
      input[type="radio"]:checked + label span:before {
        opacity: 1;
      }
    }
  }
}
textarea,
input.plain-text, /* this is a class */
input[type="text"],
input[type="button"],
input[type="submit"],
input[type=”email”],
input[type=”tel”],
input[type=”number”],
.input-radio-checkbox {
  /* this is a class */
  -webkit-appearance: none;
  border-radius: 0;
}

.MuiPagination-root {
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    li {
      button,
      div {
        font-size: 0.75em;
        color: var(--color-sub-1);
        font-weight: 600;
        min-width: 24px;
        height: 24px;
        &.Mui-selected {
          background-color: var(--color-secondary);
        }
      }
      &:last-child {
        button {
          background: url("../../assets//images/arrow.png") center no-repeat;
          background-size: 100%;
          transform: rotate(-90deg);
          svg {
            display: none;
          }
        }
      }
      &:first-child {
        button {
          background: url("../../assets//images/arrow.png") center no-repeat;
          background-size: 100%;
          transform: rotate(90deg);
          svg {
            display: none;
          }
        }
      }
    }
  }
}

.mt-4 {
    margin-top: 4px;
}
.mt-8 {
    margin-top: 8px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-12 {
    margin-top: 12px;
}
.mt-16 {
    margin-top: 16px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-24 {
    margin-top: 24px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-40 {
    margin-top: 40px;
}
.mt-50 {
    margin-top: 50px;
}
.mt-60 {
    margin-top: 60px;
}

.mt-70 {
    margin-top: 70px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-90 {
    margin-top: 90px;
}
.mt-100 {
    margin-top: 100px;
}

.mb-4 {
    margin-bottom: 4px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-30 {
    margin-bottom: 30px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-50 {
    margin-bottom: 50px;
}
.mb-60 {
    margin-bottom: 60px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-80 {
    margin-bottom: 80px;
}
.mb-90 {
    margin-bottom: 90px;
}
.mb-100 {
    margin-bottom: 100px;
}

.aspectRatio {
    position: relative;
    &.aspectRatio--100 {
        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }
    &.aspectRatio--16-9 {
        &:before {
            content: "";
            display: block;
            padding-top: 56.25%;
        }
    }
    
    &.aspectRatio--verticle {
        &:before {
            content: "";
            display: block;
            padding-top: 200%;
        }
    }
}
.img {
    img {
        width: 100%;
        display: block;
    }
}

.content-w {
    width: 100%;
    max-width: 2048px;
    margin: 0 auto;
  
    &.size-lg {
      width: 90%;
      max-width: 1600px;
      @media (max-width: 1360px) {
        width: 100%;
      }
    }
    &.size-md {
      width: 80%;
      max-width: 1200px;
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }
.desktop {
    &.container {
        max-width: 2048px;
        .page-content {
            padding: 100px 0 60px;
        }
    }
    .navigation {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        @media (max-width: 1023px) {
            height: 100%;
        }
        .page-head {
            width: auto;
            transform: translateX(0);
            position: absolute;
            top: 0;
            left: 0;
            @media (max-width: 1023px) {
                width: 100%;
            }
            .icon-menu {
                display: none;
                @media (max-width: 1023px) {
                    display: block;
                }
            }
        }
        .menu {
            position: static;
            height: auto;
            width: 100%;
            background-color: transparent;
            @media (max-width: 1023px) {
                height: 100%;
                background-color:var(--color-main);
                position: absolute;
               
            }
            .head, .info {
                display: none;
                @media (max-width: 1023px) {
                    display: flex;
                }
            }
            .wrapper {
                max-width: 2048px;
                ul {
                    display: flex;
                    justify-content: center;
                    @media (max-width: 1023px) {
                        display: block;
                      }
                    li {
                        a {
                            color: black;
                            @media (max-width: 1023px) {
                              color: white;
                            }
                        }
                    }
                }

            }
            .isDesktop {
                display: block;
                @media (max-width: 1023px){
                    display: none;
                }
            }
            .isMobile {
                display: none;
                @media (max-width: 1023px){
                    display: block;
                }
            }
        }
    }
}


.container {
    max-width: 768px;
    margin: auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    background: #000 url('../../assets/images/pagebg.png') center top no-repeat;
    background-size: 100% ;
    overflow: hidden;
    .page-content {
        padding: 20px var(--gutter) 20px;
        min-height: 100vh;
        .body-content {
            max-width: 500px;
            margin: auto;
        }
    }
}
* {
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
}

.page-head {
    display: flex;
    align-items: center;
    justify-content:center;
    z-index: 100;
    width: 100%;
    max-width: 768px;
    margin:0  auto 40px;
    transition: var(--transition);
    position: relative;
    .logo-brand {
        width: 50%;
        max-width:  300px;
        margin: auto;
    }
    .icon-menu {
        width: 36px;
        cursor: pointer;
        img {
            transition: all ease 0.5s;
        }
    }
}
.bl-title {
    margin: -30% 0 -15% -20px;
    width: calc(100% + 40px);
    @media (min-width : 450px) {
     margin: -20% 0 -15% -20px;

    }
    @media (max-width: 400px) {
        margin: -30% 0 -15% -16px;
        width: calc(100% + 32px);
    }
    img {
        max-width: 400px;
        margin: auto;
    }
}
.bottom-menu {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 768px;
        background: url('../../assets/images/menuBg.png') center no-repeat;
    background-size: cover;
    z-index: 100;
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        li {
            width: 25%;
            position: relative;
            &:last-child {
                &::before {
                    display: none;
                }
            }
            &::before {
                width: 1px;
                height: calc(100% - 30px);
                display: block;
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                background: white;
            }
            a {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
                text-decoration: none;
                height: 80px;
                &.active {
                    
                    .ic {
                        .img {
                            .ic2 {
                                display: block;
                            }
                            .ic1 {
                                display: none;
                            }
                        }
                    }
                    span {
                        color: var(--color-secondary);
                    }
                }
                .ic  {
                    text-align: center;
                    .img {
                        width: 32px;
                        margin: 0 auto 2px;
                        .ic2 {
                            display: none;
                        }
                        .ic1 {
                            display: block;
                        }

                    }
                }
                span {
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: .8125em;
                    color: white;
                    font-weight: 500;
                }
            }
        }
    }
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap');
@font-face {
    font-family: 'SFU Futura';
    src: url('../fonts/SFUFuturaExtraBold.eot');
    src: url('../fonts/SFUFuturaExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFUFuturaExtraBold.woff2') format('woff2'),
        url('../fonts/SFUFuturaExtraBold.woff') format('woff'),
        url('../fonts/SFUFuturaExtraBold.ttf') format('truetype'),
        url('../fonts/SFUFuturaExtraBold.svg#SFUFuturaExtraBold') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFU Futura';
    src: url('../fonts/SFUFuturaBold.eot');
    src: url('../fonts/SFUFuturaBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFUFuturaBold.woff2') format('woff2'),
        url('../fonts/SFUFuturaBold.woff') format('woff'),
        url('../fonts/SFUFuturaBold.ttf') format('truetype'),
        url('../fonts/SFUFuturaBold.svg#SFUFuturaBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFU Futura';
    src: url('../fonts/SFUFuturaBook.eot');
    src: url('../fonts/SFUFuturaBook.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFUFuturaBook.woff2') format('woff2'),
        url('../fonts/SFUFuturaBook.woff') format('woff'),
        url('../fonts/SFUFuturaBook.ttf') format('truetype'),
        url('../fonts/SFUFuturaBook.svg#SFUFuturaBook') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'SFU Futura';
    src: url('../fonts/SFUFuturaOblique.eot');
    src: url('../fonts/SFUFuturaOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFUFuturaOblique.woff2') format('woff2'),
        url('../fonts/SFUFuturaOblique.woff') format('woff'),
        url('../fonts/SFUFuturaOblique.ttf') format('truetype'),
        url('../fonts/SFUFuturaOblique.svg#SFUFuturaOblique') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'SFU Futura';
    src: url('../fonts/SFUFuturaHeavy.eot');
    src: url('../fonts/SFUFuturaHeavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFUFuturaHeavy.woff2') format('woff2'),
        url('../fonts/SFUFuturaHeavy.woff') format('woff'),
        url('../fonts/SFUFuturaHeavy.ttf') format('truetype'),
        url('../fonts/SFUFuturaHeavy.svg#SFUFuturaHeavy') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


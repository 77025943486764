.invalid {
	margin: 0;
	padding: 0;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f0f0f0; /* Màu nền của trang */
}

.invalid-code {
	font-size: 24px;
	font-weight: bold;
	color: #ff0000; /* Màu chữ đỏ */
}
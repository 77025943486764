.modal-show-bottom {
	.bottom-menu {
		z-index: 2000;
	}
}

.ReactModalPortal {
	.ReactModal__Overlay {
		z-index: 1000;
		justify-content: center;
		align-items: center;
		display: flex;
		background: rgba(0, 0, 0, 1) url("../../assets/images/mdBg.png") center no-repeat !important;
		background-size: 100% !important;

		.ReactModal__Content {
			inset: 0 !important;
			width: calc(100% - 40px);
			max-width: 450px;
			padding: 10vh 0 60px 0 !important;
			margin: 0 auto;
			border: NONE !important;
			display: flex;
			align-items: flex-start;
			background-color: transparent !important;

			.modal {
				width: 100%;
				position: relative;
				border-radius: 5px;
				padding: calc(var(--gutter) * 5) calc(var(--gutter) * 2.5) calc(var(--gutter) * 4);
				background-color: white;
				color: var(--color-base);

				&.md1 {
					background: url("../../assets/images/md1.png") center no-repeat;
					background-size: 100% 100%;
				}

				&.md2 {
					background: url("../../assets/images/md2.png") center no-repeat;
					background-size: 100% 100%;
				}
				&.guide {
					.md-content {
						.body {
							h4 {
								color: var(--color-secondary) !important;
							}
						}
					}
				}
				&.nexttime {
					background-color: transparent;
					padding: calc(var(--gutter) * 0) calc(var(--gutter) * 2.5) calc(var(--gutter) * 0);

					.md-content {
						.logo-brand {
							margin: 0 auto -20px;
						}

						.body {
							aspect-ratio: 0.8 /1;
							background: url("../../assets/images/nexttime.png") center no-repeat;
							background-size: 100%;
							padding-top: calc(var(--gutter) * 2);

							.icon {
								width: 100px;
								margin: 0 auto -10px;
							}

							p {
								font-size: 1.25em;
							}
						}
					}
				}

				&.share-phone {
					.head {
						display: flex;
						align-items: flex-end;
						justify-content: center;

						.ic {
							width: 60px;
						}

						span {
							font-size: 1em;
							font-weight: 900;
							font-style: italic;
							padding-left: 10px;
						}
					}

					p {
						font-size: 0.875em;

						span {
							display: inline-block;
							padding: 2px 12px 8px;
							border-radius: 50px;
							font-size: 1em;
							font-weight: bold;
							&.allow {
								background-color: #006af4;
								color: white;
							}

							&.deny {
								background-color: #eaebed;
								color: #0e0000;
							}
						}
					}
				}

				.close-md {
					width: 40px;
					position: absolute;
					top: 0px;
					right: 0%;
					z-index: 2;
					transform: translate(0%, -50%);
				}

				.md-content {
					position: relative;
					z-index: 3;

					.logo-brand {
						width: 60%;
						margin: 0 auto 20px;
					}

					.icon {
						width: 60px;
						margin: 0 auto 10px;
					}

					.qrcode {
						width: 70%;
						padding: var(--gutter);
						background-color: var(--color-primary);
						margin: 0 auto 20px;
						box-shadow: 0 0 20px 0px #49ecff;
						border-radius: 10px;
						border: 2px solid #49ecff;
					}

					.md-title {
						font-size: 1.5em;
						text-align: center;
						font-weight: bold;
						color: var(--color-primary) !important;
					}

					h4 {
						font-size: 1.25em;
						text-align: center;
						margin: 0 0 10px 0;
						color: white;
						font-weight: 500;
					}

					p {
						font-size: 1em;
						font-weight: normal;
						color: white;
						text-align: center;
						margin: 0 0 4px 0;
						line-height: 1.6;

						strong {
							font-weight: 700;
						}

						&:last-child {
							margin: 0;
						}
					}
				}
			}
		}
	}
}

.zalo-follow-only-button {
	width: 100%;
}
